import { render, staticRenderFns } from "./AlertModal.vue?vue&type=template&id=ca098eb6&scoped=true&"
import script from "./AlertModal.vue?vue&type=script&lang=js&"
export * from "./AlertModal.vue?vue&type=script&lang=js&"
import style0 from "./AlertModal.vue?vue&type=style&index=0&id=ca098eb6&lang=css&"
import style1 from "./AlertModal.vue?vue&type=style&index=1&id=ca098eb6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca098eb6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ca098eb6')) {
      api.createRecord('ca098eb6', component.options)
    } else {
      api.reload('ca098eb6', component.options)
    }
    module.hot.accept("./AlertModal.vue?vue&type=template&id=ca098eb6&scoped=true&", function () {
      api.rerender('ca098eb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dxa/Modal/AlertModal.vue"
export default component.exports